<template>
  <div class="contact-page">
    <div v-if="contactDetailsLength" class="contact-page__contact-details">
      <h4>Contact</h4>
      <p class="address">{{ contactDetails[0].field_address }}</p>
      <div class="dept">
        <p class="title">Info :</p>
        <p>
          <a class="dept-mail" :href="mailtoInfo()">{{ contactDetails[0].field_info }} </a>
        </p>
      </div>
      <div class="dept">
        <p class="title">Investment :</p>
        <p>
          <a class="dept-mail" :href="mailtoInvest()">{{ contactDetails[0].field_start_up }} </a>
        </p>
      </div>
      <div class="contact-page__icon-block">
        <p class="follow">Follow us</p>
        <div class="contact-page__icons">
          <a href="https://www.facebook.com/GDPVenture/" target="_blank" rel="noopener noreferrer">
            <img class="icon" src="@/assets/img/facebook_blue.svg" />
          </a>
          <a href="https://twitter.com/gdpventure?s=09" target="_blank" rel="noopener noreferrer">
            <img class="icon" src="@/assets/img/twitter_blue.svg" />
          </a>
          <a href="https://instagram.com/gdpventure?igshid=1fsfgqnpzcb9u" target="_blank" rel="noopener noreferrer">
            <img class="icon" src="@/assets/img/ig.svg" />
          </a>
          <a href="https://www.youtube.com/channel/UC-h9s3_7M5_U1nKUqMWjJ3A" target="_blank" rel="noopener noreferrer">
            <img class="icon" src="@/assets/img/youtube.svg" />
          </a>
          <a
            href="https://www.linkedin.com/company/pt-global-digital-prima-gdp-venture/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img class="icon" src="@/assets/img/linkedin.svg" />
          </a>
        </div>
      </div>
    </div>
    <div class="contact-page__contact-map">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.google.com/maps/place/Wisma+77+Tower+2/@-6.1899474,106.7982509,19z/data=!4m6!3m5!1s0x2e69f7004db49f9f:0x91809a5487e4e606!8m2!3d-6.1898241!4d106.7986843!16s%2Fg%2F11vwmr206k?entry=ttu&g_ep=EgoyMDI1MDIxOS4xIKXMDSoASAFQAw%3D%3D"
      >
        <img class="map-img" src="@/assets/img/contact-cropped-map.png" />
      </a>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'Contact',
  computed: {
    ...mapState('contact', ['contactDetails']),
    contactDetailsLength() {
      return !!this.contactDetails.length
    }
  },
  mounted() {
    this.getContactDetails()
  },
  methods: {
    ...mapActions('contact', ['getContactDetails']),
    mailtoInfo() {
      return 'mailto:' + this.contactDetails[0].field_info
    },
    mailtoInvest() {
      return 'mailto:' + this.contactDetails[0].field_start_up
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/colors';
@import '@/assets/scss/fonts';
@import '@/assets/scss/media-query-mixins';
.contact-page {
  display: flex;
  @include ipad-10-portrait {
    flex-direction: column;
  }
  @include ipad-default-portrait {
    flex-direction: column;
  }
  @include iphone-portrait {
    flex-direction: column;
  }
  @include ipad-default-portrait {
    margin-top: 150px;
  }
  @media (min-width: 897px) and (max-width: 1023px) {
    flex-direction: column;
  }
  &__contact-details {
    width: 40%;
    margin: 50px 0 80px 82px;
    @include iphone-portrait {
      margin: 37px 0 0 16px;
    }
    @include ipad-10-portrait {
      margin: 50px 0 0 50px !important;
    }
    @include ipad-10-landscape {
      margin: 0 0 0 82px !important;
    }
    @include ipad-portrait {
      margin: 37px 0 0 51px !important;
    }
    @include ipad-default-portrait {
      margin: 0 0 0 51px;
    }
    @include larger-screens {
      margin-top: 0;
      width: 50%;
    }
    h4 {
      font-family: GothamMedium;
      font-size: 32px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      text-align: left;
      color: $primary-black;
      margin: 0;
      @include ipad-10-portrait {
        font-size: 24px;
        line-height: 1.42;
      }
      @include iphone-portrait {
        font-size: 24px;
        line-height: 1.42;
      }
      @include larger-screens {
        font-size: 40px;
        line-height: 2;
      }
    }
    .address {
      margin: 10px 0 50px 0;
      width: 275px;
      object-fit: contain;
      font-family: GothamBook;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: 0.29px;
      text-align: left;
      color: $light-gray;
      @include larger-screens {
        font-size: 20px;
      }
      @include ipad-landscape {
        margin: 10px 0 20px 0;
      }
    }
    .title {
      font-size: 18px;
      font-family: GothamBook;
      font-weight: normal;
      color: $primary-black;
      margin: 0;
      @include larger-screens {
        font-size: 20px;
      }
    }
    .dept-mail {
      object-fit: contain;
      font-family: GothamMedium;
      margin-bottom: 30px;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      text-align: left;
      color: $dark-blue-color;
      text-decoration: none;
      @include larger-screens {
        font-size: 24px;
      }
    }
    .follow {
      font-family: GothamBook;
      font-size: 18px;
      line-height: 1.56;
      letter-spacing: normal;
      text-align: left;
      color: $primary-black;
      margin: 0;
    }
  }
  &__icons {
    margin-bottom: 150px;
    @include ipad-landscape {
      margin-bottom: 260px;
    }
    @include ipad-10-portrait {
      margin-bottom: 50px;
    }
    @include ipad-default-portrait {
      margin-bottom: 50px;
    }
    @include iphone-portrait {
      width: 200px;
      margin-bottom: 30px;
    }
    @media (min-width: 897px) and (max-width: 1023px) {
      margin-bottom: 0;
    }
    .icon {
      height: 21px;
      width: 21px;
      margin: 14px 19px 0 0;
      @include larger-screens {
        height: 25px;
        width: 25px;
        margin: 14px 25px 0 0;
      }
    }
  }
  &__contact-map {
    @include computers {
      width: 60%;
      margin: 50px 80px 80px 0;
    }
    @include ipad-landscape {
      width: 90%;
    }
    @include ipad-10-portrait {
      width: 90% !important;
      margin: 0 50px 100px 50px !important;
    }
    @include iphone-portrait {
      width: 90%;
      margin: 0 16px 60px 16px;
    }
    @include ipad-portrait {
      width: 85%;
      margin: 0 16px 60px 51px;
    }
    @media (min-width: 978px) and (max-width: 1023px) {
      width: 90%;
      margin: 0 16px 60px 82px;
    }
    @media (min-width: 930px) and (max-width: 977px) {
      width: 100%;
      margin: 30px 16px 60px 51px;
    }
    @include ipad-default-portrait {
      width: 90%;
    }
    @media (min-width: 1025px) and (max-width: 1279px) {
      width: 60%;
      margin: 0 80px 80px 0;
    }
    @include larger-screens {
      margin: 0 80px 80px 0;
    }
    .map-img {
      border-radius: 17px;
      margin: 0 0 0 40px;
      width: 90%;
      height: 450px;
      object-fit: cover;
      @include ipad-default-landscape {
        object-fit: cover;
        height: 480px;
        border-radius: 20px;
        width: 100%;
        margin: 0;
      }
      @include ipad-10-portrait {
        margin: 0 !important;
        width: 98% !important;
        height: 470px !important;
      }
      @include iphone-portrait {
        margin: 0 !important;
        width: 100%;
        height: 40vh;
        object-fit: cover;
        border-radius: 20px;
      }
      @include ipad-portrait {
        margin: 0 51px 0 0 !important;
        height: auto;
      }
      @media (min-width: 897px) and (max-width: 1024px) {
        height: auto !important;
        margin: 30px 0 60px 0;
        width: 100% !important;
      }
      @include ipad-landscape {
        margin: 50px 80px 0 30px;
        height: 400px;
        width: 90% !important;
        object-fit: cover;
        border-radius: 15px;
      }
      @media (min-width: 930px) and(max-width:1023px) {
        margin: 0 0 0 0 !important;
        width: 93% !important;
      }
      @include larger-screens {
        height: auto !important;
        width: 100%;
        margin: 0;
      }
      @include ipad-10-landscape {
        margin-top: 0 !important;
        border-radius: 20px;
      }
      @include computers {
        margin: 0;
        width: 100%;
      }
      @media (min-width: 1025px) and (max-width: 1279px) {
        margin-top: 50px;
        border-radius: 20px;
        margin-left: 0;
        width: 100%;
      }
      @media (min-width: 1024px) and (max-width: 1024px) and(orientation: landscape) {
        width: 83% !important;
      }
    }
  }
}
</style>
